.golf-course-button {
  height: 48px;
  width: 48px;
  margin: 0 2px 2px;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.golf-course-field-wrapper {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.golf-course-button-text {
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.golf-course-field-text {
  font-size: 14px;
  color: black;
  text-align: center;
  margin: 0;
}

.golf-course-hr {
  height: 2px;
  color: gray;

  width: 100%;
  margin: 0;
}

.golf-course-label-wrapper {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: 10px;

}

.golf-course-label {
  font-size: 14px;
  color: black;
  font-weight: bold;
  text-align: center;
  margin: 0;
  user-select: none;
}

/* Modal */

.browse-courses-modal {
  width: 95vw !important;
  max-width: 1200px !important;

  .modal-content {
    //height: 100%;
    //height: 75vh !important;
    max-height: 1200px !important;
    //overflow-y: scroll;
  }
}

.create-courses-modal {
  width: 95vw !important;
  max-width: 1200px !important;
}
